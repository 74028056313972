import React from 'react';

interface Props {
  value: boolean;
  onChange: (value) => void;
  label: string | JSX.Element;
  small?: boolean;
  disabled?: boolean;
}

const Checkbox: React.FC<Props> = ({
  label,
  value,
  onChange,
  small = false,
  disabled = false,
}) => (
  <div
    className='flex items-start cursor-pointer'
    onClick={() => (!disabled ? onChange(!value) : null)}
  >
    <div className='flex items-center h-5'>
      <input
        onChange={() => (!disabled ? onChange(!value) : null)}
        disabled={disabled}
        checked={value}
        type='checkbox'
        className={`form-checkbox ${
          disabled ? 'text-gray-400' : 'text-orange-400'
        }  w-4 h-4 rounded border-gray-200 border`}
      />
    </div>
    <div
      className={`ml-3 text${small ? '-xs' : ''}`}
      onClick={() => (!disabled ? onChange(!value) : null)}
    >
      <p className={disabled ? 'text-gray-300' : ''}>{label}</p>
    </div>
  </div>
);

export default Checkbox;
