import React from 'react';

interface Props {
  label?: string;
  value: string;
  options: { value: string; key: string }[];
  onChange: (value: string) => void;
  disabled?: boolean;
}

const Select: React.FC<Props> = ({
  onChange,
  options,
  label,
  value,
  disabled = false,
}) => {
  return (
    <>
      <select
        disabled={disabled}
        value={value}
        className={`form-select block w-full border-b ${
          value && value.length > 0 ? 'text-gray-700' : 'text-gray-400'
        } ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}`}
        onChange={(e) => onChange(e.currentTarget.value)}
      >
        {label ? (
          <option selected disabled>
            {label}
          </option>
        ) : null}
        {options.map((option) => (
          <option value={option.key} key={option.key}>
            {option.value}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
