import { format, isValid, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import Select from "../utils/Select";

interface Props {
  value: string | null;
  onChange: (newValue: string | null) => void;
  label: string;
  minYear?: number;
  maxYear?: number;
}

const InputDate: React.FC<Props> = ({
  label,
  value,
  onChange,
  minYear = 1900,
  maxYear = 2010,
}) => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState(null as string | null);
  const [year, setYear] = useState("");

  const isNumber = (n: any) => /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  const toNumberString = (n: any, defaultValue: string | null = null) =>
    isNumber(n) ? parseInt(n).toString() : defaultValue;

  useEffect(() => {
    if (value) {
      const splitted = value.split("-");
      if (
        splitted.length === 3 &&
        isNumber(splitted[0]) &&
        isNumber(splitted[1]) &&
        isNumber(splitted[2])
      ) {
        setDay(parseInt(splitted[2]).toString());
        setMonth(parseInt(splitted[1]).toString());
        setYear(parseInt(splitted[0]).toString());
      } else {
        onChange(null);
      }
    }
  }, []);

  const realChange = (property: "day" | "month" | "year", value: string) => {
    const dateObj = {
      day,
      month,
      year,
    };

    const dateFun = { day: setDay, month: setMonth, year: setYear };

    const isNum = (input: any) =>
      parseInt(input) !== null && !isNaN(parseInt(input));

    const newValue = value.replace(/[^\d]/g, "");
    dateObj[property] = newValue;
    dateFun[property](dateObj[property]);

    if (isNum(dateObj.day)) {
      const maxDays = !isNum(dateObj.month)
        ? 31
        : new Date(
            isNum(dateObj.year) ? parseInt(dateObj.year) : 1900,
            Number(parseInt(dateObj.month)),
            0
          ).getDate();

      dateObj.day = Math.min(Number(dateObj.day), maxDays).toString();
      dateFun.day(dateObj.day);
    }

    if (isNum(dateObj.year)) {
      dateObj.year = Math.min(maxYear, Number(dateObj.year)).toString();
      dateFun.year(dateObj.year);
    }

    const dateString = Object.values(dateObj).join("-");
    const parsedDate = parse(dateString, "d-M-yyyy", new Date());
    const isValidDate = isValid(parsedDate);
    onChange(isValidDate ? format(parsedDate, "yyyy-MM-dd") : null);
  };

  const onYearBlur = (value: string) => {
    const isNum = (input: any) =>
      parseInt(input) !== null && !isNaN(parseInt(input));

    const newValue = value.replace(/[^\d]/g, "");

    if (isNum(newValue)) {
      realChange(
        "year",
        Math.max(minYear, Math.min(maxYear, parseInt(newValue))).toString()
      );
    }
  };

  return (
    <>
      <label className="-mb-2 block px-2 text-xs text-gray-400">{label}</label>

      <div className="flex space-x-2">
        <div className={`relative border-b`}>
          <input
            placeholder="Dzień"
            className={[
              "w-20 rounded bg-transparent p-2 outline-none transition-all duration-200 ease-in-out",
            ].join(" ")}
            value={day}
            onChange={(e) => realChange("day", e.currentTarget.value)}
          />
        </div>
        <Select
          label="Miesiąc"
          value={month}
          onChange={(newMonth) => realChange("month", newMonth)}
          options={[
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ].map((month, index) => ({
            key: (index + 1).toString(),
            value: month,
          }))}
        />
        <div className={`relative border-b`}>
          <input
            placeholder="Rok"
            className={[
              "w-16 rounded bg-transparent p-2 outline-none transition-all duration-200 ease-in-out",
            ].join(" ")}
            value={year}
            onChange={(e) => realChange("year", e.currentTarget.value)}
            onBlur={(e) => {
              onYearBlur(e.currentTarget.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default InputDate;
