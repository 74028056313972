import { Form, Formik } from "formik";
import React, { useState } from "react";
import Checkbox from "../form/Checkbox";
import LoadingIcon from "../utils/LoadingIcon";
import { useCustomer } from "@wk93/socommerce-sdk";
import InputDate from "../form/InputDate";
import Input from "../form/Input";

interface Props {
  onSubmit: () => void;
  btnClassName?: (loading: boolean) => string;
  edroneTag?: string;
}

const NewsletterForm: React.FC<Props> = (
  { onSubmit, children, btnClassName, edroneTag = "Footer" },
) => {
  const {
    subscribe,
    validationSchemas: { action },
  } = useCustomer();
  const [loading, setLoading] = useState(false);

  const firstCheckLabel =
    "Wyrażam zgodę na przetwarzanie podanych powyżej danych osobowych w celu otrzymywania newslettera.";
  const secondCheckLabel =
    "Wyrażam zgodę na otrzymywanie informacji handlowych o wybranych produktach.";

  return (
    <Formik
      initialValues={{
        email: "",
        firstChecked: false,
        secondChecked: false,
        birthdate: "",
      }}
      validationSchema={action}
      validate={(values) => {
        const errors = {} as Record<string, string>;

        if (!values.firstChecked) {
          errors.firstChecked = "Pole wymagane";
        }

        if (!values.secondChecked) {
          errors.secondChecked = "Pole wymagane";
        }

        return Object.keys(errors).length > 0 ? errors : undefined;
      }}
      onSubmit={async (values, { setErrors, setValues, setTouched }) => {
        setLoading(true);
        // const message = await subscribe(values.email);

        // if (message === 'error') {
        //   setErrors({ email: 'Email już istnieje w naszej bazie.' });
        // } else {
        //   onSubmit();
        //   setValues({ email: '', firstChecked: false, secondChecked: false });
        //   setTouched({
        //     email: false,
        //     firstChecked: false,
        //     secondChecked: false,
        //   });
        // }

        const _edrone = (window as any)._edrone;

        if (_edrone && _edrone.init) {
          _edrone.email = values.email;
          _edrone.action_type = "subscribe";
          _edrone.birth_date = values.birthdate;
          _edrone.customer_tags = edroneTag;
          _edrone.init();
        }

        onSubmit();

        setLoading(false);
      }}
    >
      {({ errors, values, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          {children}

          <div className="rounded bg-white p-2 text-gray-700">
            <div className="mb-2">
              <Input label="Adres email" name="email" />
            </div>
            <div className="mb-2">
              <InputDate
                label="Data urodzenia"
                value={values.birthdate}
                onChange={(value) => setFieldValue("birthdate", value)}
              />
            </div>

            <div className="mb-2">
              <button
                type="submit"
                className={`hover:bg-green-dark my-1 inline-flex w-full items-center justify-center rounded py-2 text-center text-white focus:outline-none ${
                  btnClassName
                    ? btnClassName(loading)
                    : loading
                    ? "disabled cursor-not-allowed border-orange-500 bg-orange-500"
                    : "border-orange-400  bg-orange-400 hover:border-orange-500 hover:bg-orange-500"
                }
                  `}
              >
                {loading
                  ? <LoadingIcon className="-ml-1 mr-3 h-5 w-5 text-white" />
                  : null}
                Dołącz
              </button>
            </div>
            <div className="mt-4">
              <div className="pb-2">
                <Checkbox
                  value={values.firstChecked}
                  onChange={(value) => {
                    setFieldValue("firstChecked", value);
                    setFieldTouched("firstChecked", true);
                  }}
                  label={touched.firstChecked && errors.firstChecked
                    ? (
                      <>
                        {firstCheckLabel}{" "}
                        <strong className="text-orange-500">
                          {errors.firstChecked}
                        </strong>
                      </>
                    )
                    : firstCheckLabel}
                  small
                />
              </div>
              <div className="pb-2">
                <Checkbox
                  value={values.secondChecked}
                  onChange={(value) => {
                    setFieldValue("secondChecked", value);
                    setFieldTouched("secondChecked", true);
                  }}
                  label={touched.secondChecked && errors.secondChecked
                    ? (
                      <>
                        {secondCheckLabel}{" "}
                        <strong className="text-orange-500">
                          {errors.secondChecked}
                        </strong>
                      </>
                    )
                    : secondCheckLabel}
                  small
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewsletterForm;
