import { useField } from 'formik';
import React, { useEffect } from 'react';

interface Props {
  label: string;
  type?: string;
  name: string;
  errorHelper?: boolean;
  disabled?: boolean;
}

const Input: React.FC<Props> = ({
  type = 'text',
  name,
  label,
  errorHelper = true,
  disabled = false,
}) => {
  const [field, meta, helpers] = useField<string>({ name });
  const [active, setActive] = React.useState(field.value.length > 0);
  const showError = meta.touched && meta.error;
  const classBorder = showError ? 'border-orange-500' : 'border-grey-light';
  const classText = showError
    ? 'text-orange-600'
    : 'text-gray-700 text-opacity-50';

  useEffect(() => {
    setActive(field.value.length > 0);
  }, [field.value]);

  function handleActivation(e) {
    setActive(!!e.target.value);
    field.onChange(e);
  }

  return (
    <div className={`relative border-b ${classBorder}`}>
      <input
        disabled={disabled}
        className={[
          'outline-none w-full rounded bg-transparent transition-all duration-200 ease-in-out p-2',
          active ? 'pt-6' : '',
          disabled ? 'bg-gray-100 cursor-not-allowed' : '',
        ].join(' ')}
        id={name}
        name={name}
        type={type}
        value={field.value}
        onChange={handleActivation}
        onBlur={field.onBlur}
      />
      <label
        className={[
          `absolute top-0 left-0 flex items-center ${classText} p-2 transition-all duration-200 ease-in-out`,
          active ? 'text-xs' : 'text',
        ].join(' ')}
        htmlFor={name}
      >
        {label}{' '}
        {showError && errorHelper ? (
          <span className='ml-1'>({meta.error})</span>
        ) : null}
      </label>
    </div>
  );
};

export default Input;
