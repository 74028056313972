import React, { useState } from "react";
import Modal from "../utils/Modal";
import Title from "../utils/Title";
import NewsletterForm from "./NewsletterForm";

interface Props {
  edroneTag?: string;
  btnClassName?: (loading: boolean) => string;
}

const Newsletter: React.FC<Props> = ({ btnClassName, edroneTag }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Modal
        show={modalVisible}
        title="Dziękujemy za zapisanie się do newslettera"
        description="Na podany adres mailowy wysłaliśmy wiadomość z linkiem potwierdzającym."
        type="success"
      >
        <button
          onClick={() => setModalVisible(!modalVisible)}
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-400 text-base font-medium text-white hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Dziękuję
        </button>
      </Modal>
      <div>
        <NewsletterForm
          edroneTag={edroneTag}
          onSubmit={() => setModalVisible(true)}
          btnClassName={btnClassName}
        />
      </div>
    </>
  );
};

export default Newsletter;
